* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

body,
html {
  overflow-x: hidden;
}

.main {
  background-color: var(--background-color-main);
  padding-bottom: 55px;
  font-family: 'Comfortaa', cursive;
  padding-top: 50px;
}

.dark {
  --background-color-main: #38B6FF;
  --color-figure: #004F8E;
  --switch-color: white;
  --navbar-color: white;
  --nav-link-color: #004F8E;
  --nav-language: #004F8E;
  --nav-button: #38B6FF;
  --features: white;
  --features-text: #004F8E;
  --about: #004F8E;
  --footer: white;
  --footer-hr: black;
  --burger: white;
  --li: black;
  font-family: 'Comfortaa', cursive;
  --back: white;
  --back-title: black;
}

.light {
  --background-color-main: #2F2F2F;
  --color-figure: #0023A0;
  --switch-color: #003661;
  --navbar-color: black;
  --nav-link-color: white;
  --nav-language: white;
  --nav-button: white;
  --features: #1C1C1C;
  --features-text: white;
  --about: black;
  --footer: black;
  --footer-hr: white;
  --burger: #2F2F2F;
  --li: white;
  font-family: 'Comfortaa', cursive;
  --back: #004F8E;
  --back-title: white;
}

.container {
  max-width: 1450px;
  width: 100%;
  margin: 0 auto;

}

.nav_logo {
  width: 60px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header_figure {
  width: 100%;
  max-width: 1065px;
  height: 765px;
  position: absolute;
  background: var(--color-figure);
  left: 0;
  border-radius: 0% 0% 100% 0%;
  z-index: 1;
  top: 0;
}

.nav_menu {
  width: 100%;
  background-color: var(--navbar-color);
  z-index: 999;
  position: fixed;
  top: 0;
  transition: 0.4s;
}

.nav_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav_links {
  width: 950px;
}

.nav_links_mob {
  width: 950px;
}

.nav_link {
  margin-right: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--nav-link-color);
  text-decoration: none;
}

.nav_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch_language {
  font-weight: 700;
  font-size: 15px;
  color: #004F8E;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.switch_language span {
  margin-left: 3px;
  margin-right: 3px;
  color: var(--nav-language);
  user-select: none;
}

.switch_language p {
  text-decoration: none;
  color: var(--nav-language);
  transition: 0.3s;
  user-select: none;
  cursor: pointer;
}

.switch_language p:hover {
  color: #E6556A;
}

.open_bot {
  width: 175px;
  height: 40px;
  border: 2px solid var(--nav-button);
  border-radius: 7px;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  color: var(--nav-language);
  cursor: pointer;
  transition: 0.3s;
}

.open_bot:hover {
  background-color: #38B6FF;
  border: 2px solid var(--nav-button);
  color: white;
}

.switch_theme {
  width: 55px;
}

.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: .5rem;
  width: 46px;
  height: 26px;
  background-color: #38B6FF;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #38B6FF;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: var(--switch-color);
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked+i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked+i {
  background-color: #38B6FF;
}

.form-switch input:checked+i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked+i::after {
  transform: translate3d(22px, 2px, 0);
}

.header_body {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  align-items: center;
  max-width: 1300px;
}

.header_body img {
  padding-top: 50px;
  padding-left: 80px;
}

.header_body_content {
  max-width: 750px;
}

.header_body_content h1 {
  color: white;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.header_body_content h2 {
  color: white;
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.header_body_content ul {
  margin-left: 60px;
}

.header_body_content li {
  color: white;
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
}

.header_body_buttons {
  margin-top: 40px;
  font-family: 'Comfortaa', cursive;
}

.body_buttons_left {
  min-width: 233px;
  height: 54px;
  border: 3px solid #5271FF;
  font-weight: 700;
  font-size: 21px;
  color: white;
  margin-right: 20px;
  border-radius: 7px;
  font-family: 'Comfortaa', cursive;
  background: #5271FF;
  cursor: pointer;
  transition: 0.3s;
  padding-left: 10px;
  padding-right: 10px;
}

.body_buttons_right {
  width: 233px;
  height: 54px;
  background: transparent;
  font-family: 'Comfortaa', cursive;
  border: 3px solid #FFFFFF;
  font-weight: 700;
  font-size: 21px;
  color: white;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.3s;
}

.body_buttons_left:hover {
  border: 3px solid white;
  background-color: transparent;
}

.body_buttons_right:hover {

  background-color: white;
  color: #5271FF;
}

.switch_dark {
  position: absolute;
  right: 3px;
  top: 4px;
  user-select: none;
}

.switch_light {
  width: 18px;
  position: absolute;
  left: 3px;
  top: 4px;
  user-select: none;
}

.features {
  background-color: var(--features);
  width: 100%;
  padding-bottom: 50px;
}

.navbar {
  position: relative;
  z-index: 999;
  height: 62px;
  width: 100%;
  background-color: #ccc;
}

.sticky-nav {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    top: -50px;
  }

  100% {
    top: 0;
  }
}

.features_plot {
  font-weight: 700;
  font-size: 35px;
  color: var(--features-text);
  text-align: center;
  padding-top: 80px;
  margin-bottom: 50px;
}



.features_body {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.features_info_block {
  width: 450px;
}

.features_info_left {
  text-align: right;
}

.features_info_block h2 {
  font-weight: 700;
  font-size: 24px;
  color: var(--features-text);
}

.features_info_block p {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--features-text);
  margin-top: 20px;
}

.features_info_right {
  margin-top: 80px;
}

.features_info_left {
  margin-top: 80px;
}

.price {
  width: 100%;
  height: 120vh;
  background-color: var(--background-color-main);
}

.price_figure_left {
  width: 700px;
  height: 700px;
  border-radius: 100%;
  background-color: var(--color-figure);
  position: absolute;
  margin-top: 200px;
  left: -400px;
}

.price_figure_right {
  max-width: 600px;
  width: 100%;
  height: 500px;
  position: absolute;
  background: var(--color-figure);
  right: 0;
  border-radius: 0% 0% 0% 100%;
  z-index: 1;
}

.price_list {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.price_block {
  width: 340px;
  height: 700px;
  background-color: var(--features);
  border-radius: 17px;
  position: relative;
  padding: 20px;
}

.price_plot {
  font-weight: 700;
  font-size: 35px;
  color: white;
  text-align: center;
  padding-top: 80px;
  margin-bottom: 100px;

}

.price_block h2 {
  text-align: center;
  font-weight: 700;
  font-size: 33px;
  color: var(--features-text);
  margin-top: 40px;
  margin-bottom: 20px;
}

.price_block p {

  color: var(--features-text);
  font-weight: 500;
  font-size: 16px;
  margin-top: 15px;
  line-height: 29px;
}

.cost {
  text-align: center;
  font-weight: 300;
  font-size: 60px;
  color: var(--features-text);
  position: absolute;
  bottom: 80px;
  right: 0;
  left: 0;
}

.cost span {
  color: var(--features-text);
  font-weight: 700;
  font-size: 30px;
}

.price_block_buy {
  width: 90%;
  margin: 0 auto;
  height: 50px;
  background: #5271FF;
  border-radius: 12px;
  font-weight: 300;
  font-size: 25px;
  color: white;
  border: 2px solid #5271FF;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 0;
  left: 0;
}

.price_block img {
  margin-right: 6px;
}

.price_block_price {
  position: absolute;
  bottom: 80px;
}

.price_block_currency {
  font-size: 23px;
}

.price_block_number {
  font-size: 40px;
}

.price_block_body p {
  margin: 0 auto;
  text-align: left;
}

.price_block_body h3 {
  margin-top: 15px;
  margin-bottom: 15px;
  color: var(--features-text);
}

.price_circle {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #5271FF;
  position: absolute;
  right: 10px;
  top: 10px;
}

.about {
  width: 100%;
  height: 100vh;
  background-color: var(--about);
}

.about_top {
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
  align-items: center;
}

.about_textarea {
  max-width: 700px;
}

.about_textarea h2 {
  font-weight: 700;
  font-size: 40px;
  color: white;
  margin-top: 30px;
}

.about_textarea p {
  font-weight: 300;
  font-size: 18px;
  color: white;
  line-height: 167%;
  margin-top: 20px;
}

.about_img_line {
  width: 100%;
  max-width: 300px;
  height: 3px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}

.about_img_line div {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 100%;
}

.about_img_list {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 80%;
  align-items: flex-start;
  margin-top: 120px;
}

.about_img_list p {
  width: 100%;
  max-width: 170px;
  text-align: center;
  font-weight: 300;
  font-size: 15px;
  color: white;
  line-height: 29px;
  margin-top: 20px;
}

.about_img_block {
  text-align: center;
}

.about_top img {
  width: 400px;
  height: 350px;
}

footer {
  background-color: var(--footer);
  padding-top: 50px;
  padding-bottom: 20px;
}

footer hr {
  background-color: var(--footer-hr);
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 2px;
  border-radius: 12px;
}

.policy {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 240px;
  margin-bottom: 10px;
}

.copyright {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
}

.policy a {
  color: var(--footer-hr);
}

.nav_body_mob {
  display: none;
}

.burger-menu_switch {
  display: none;
}

.mobile-burger {
  display: none;
}

.mobile-burger label {
  margin-right: 15px;
}

.nav_buttons_desctop {
  display: flex;
}

.open_bot_mob {
  display: none;
}

.burger-menu_svg {
  width: 25px;
  margin-top: 4px;
}

.burger-menu_svg div {
  width: 100%;
  height: 3px;
  background-color: var(--nav-link-color);
  margin-bottom: 3px;
  border-radius: 5px;
}

.burger-menu {
  background-color: var(--burger) !important;
}

.menu a {
  width: 150px;
  color: var(--nav-link-color) !important;
}

.features_body_bottom {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  color: #5271FF;
  margin-top: 30px;
}

.features_body_bottom li {
  font-size: 13px;
  color: var(--li);
  border-radius: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.contact {
  background-color: #004F8E;
  padding-top: 100px;
  padding-bottom: 100px;
}

.contact_list {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.contact_input_list {
  display: block;
  width: 100%;
  max-width: 450px;
}

.contact_input_item {
  margin-bottom: 40px;
}

.contact_input_item input {
  width: 100%;
  outline: none;
  height: 30px;
  background: transparent;
  border: none;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  color: white;
}

.contact_input_item input::placeholder {
  color: rgba(255, 255, 255, 0.49);
}

.contact_input_item hr {
  height: 5px;
  border-radius: 15px;
  background-color: white;
  margin-top: 5px;
}

.contact_input_list button {
  height: 50px;
  background: #FFFFFF;
  border-radius: 47px;
  border: 2px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 30px;
  color: #004F8E;
  cursor: pointer;
  font-family: 'Comfortaa', cursive;
}

.contact_input_list h2 {
  font-weight: 700;
  font-size: 40px;
  color: white;
  margin-bottom: 60px;
}

.contact_input_list button {
  width: 100%;
}

.blog {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 90px;
  background-color: var(--background-color-main);
}

.blog_item_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--features);
  border-radius: 15px;
  width: 100%;
  padding: 5px 20px 2px 20px;
  margin-top: 15px;
}

.blog_item_top p {
  font-weight: 700;
  font-size: 25px;
  color: var(--features-text);
}

.blog_item_top img {
  width: 40px;
  height: 40px;
}

.blog_item_bottom {
  display: flex;
  justify-content: space-between;
}

.blog_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--features);
  border-radius: 15px;
  width: 49.5%;
  padding: 5px 20px 2px 20px;
  margin-top: 15px;
}

.blog_item img {
  width: 40px;
  height: 40px;
}

.blog_item p {
  font-weight: 700;
  font-size: 25px;
  color: var(--features-text);
}

.blog_plot {
  font-weight: 700;
  font-size: 35px;
  color: white;
  text-align: center;
  padding-top: 30px;
  margin-bottom: 50px;

}

.active_block {
  color: white;
  font-family: 'Roboto', sans-serif !important;
}

.active_block h2 {
  font-size: 28px;
}

.active_block p {
  font-size: 22px;
  font-weight: 200;
}

.main_dashboard {
  min-height: 100vh;
  padding-top: 80px;
  background-color: var(--background-color-main);
  padding-bottom: 55px;

}

.video {
  margin-top: 20px;
  width: 100%;
  height: 600px;
  border: 1px solid black;
  border-radius: 12px;
}

.response {
  background-color: var(--features);
  width: 100%;
  padding-bottom: 50px;
}

.response_block {
  display: flex;
  margin: 0 auto;
  height: 640px;
  width: 100%;
  max-width: 330px;
  position: relative;
  z-index: 999;
  border-radius: 20px;
  box-shadow: 0 0 25px #0023A0;
  border: 7px solid black;
}

.swiper-wrapper {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.response_a {
  text-decoration: none;
  text-align: center;
}

.response_btn_item {
  text-align: center;
  margin-top: 20px;
}

.response_btn {
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 15px;
  background-color: #5271FF;
  color: white;
  border: none;
  cursor: pointer;
  font-family: 'Comfortaa', cursive;
}

.contact_input_item input {
  font-family: 'Comfortaa', cursive;
}

.back {
  width: 100px;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  background-color: var(--back);
  color: var(--back-title);
  font-family: 'Comfortaa', cursive;
  font-size: 16px;
}

@media (max-width: 1500px) {
  .nav_body {
    margin-left: 20px;
    margin-right: 20px;
  }

  .nav_links {
    margin-left: 10px;
  }
}

@media (max-width: 1450px) {

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .container_block {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav_body_mob {
    padding-left: 20px;
  }

  .price {
    height: unset;
  }

  .menu {
    background-color: unset !important;
  }

  .price_list {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    flex-wrap: wrap;
    max-width: 944px;
  }

  .price_block {
    margin-bottom: 40px;
    width: 450px;
  }

  .price_plot {
    z-index: 10;
    position: relative;
  }

  .header_body img {
    padding-left: 0px;
  }

  .about {
    height: unset;
    padding-bottom: 40px;
  }

  .about_img_list p {
    font-size: 20px;
  }

  .about_img_line {
    display: none;
  }

  .about_img_block {
    margin-top: 30px;
  }

  .copyright {
    margin-top: 0px;
  }

  .switch_language {
    font-size: 21px;
  }
}

@media (max-width: 1360px) {
  .nav_links {
    display: flex;
    line-height: 25px;
    flex-wrap: wrap;
  }
}

@media (max-width: 1300px) {
  .features_body {
    display: block;
  }

  .features_body img {
    display: flex;
    margin: 0 auto;
  }

  .features {
    height: unset;
    padding-bottom: 40px;
  }

  .features_info_block {
    width: unset;
    text-align: center;
  }

  .nav_link {
    margin-right: 20px;
  }
}

@media (max-width: 1080px) {
  .contact_logo {
    display: none;
  }

  .contact_input_list {
    max-width: unset;
  }

  .body_buttons_left {
    width: 100%;
    margin-bottom: 20px;
  }

  .body_buttons_right {
    width: 100%;
  }

  .about_img_list {
    display: block;
    margin-top: unset;
  }

  .open_bot_mob {
    display: block;
    margin-left: 5px;
    margin-right: 5px;
  }

  .nav_links_mob {
    display: none;
  }

  .header_body img {
    display: flex;
    margin: 0 auto;
  }

  .main {
    height: unset;
  }

  .nav_links_mob {
    display: grid;
    gap: 15px;
  }

  .nav_buttons_mob {
    display: none;
  }

  .nav_body_mob {
    display: block;
  }

  .burger-menu_switch {
    display: block;
  }

  .nav_links {
    margin-left: 0px;
  }

  .mobile-burger {
    display: flex;
    align-items: center;
  }

  .nav_links_desctop {
    display: none;
  }

  .nav_buttons {
    display: block;
    margin-top: 50px;
  }

  .nav_buttons_desctop {
    display: none;
  }

  .switch_language {
    margin-left: unset;
    margin-bottom: 10px;
  }



  .nav_link {
    font-size: 25px;
  }

  .open_bot {

    font-size: 20px;
  }

  .about_img_list p {
    max-width: unset;
    font-size: 20px;
  }

  .features_body_bottom {
    display: block;
  }

  .features_body_bottom p {
    margin-top: 10px;
    margin-left: unset;
    margin-right: unset;
  }

  .nav_footer {
    display: none;
  }

  footer hr {
    display: none;
  }
}

@media (max-width: 950px) {
  .blog_item_bottom {
    display: block;
  }

  .blog_item {
    width: 100%;
  }

  .header_body {
    display: block;
  }

  .body_buttons_left {
    width: 100%;
  }

  .body_buttons_right {
    width: 100%;
  }

  .header_body_content {
    max-width: unset;
  }

  .header_body_buttons {
    padding-bottom: 30px;
  }

  .nav_body {
    margin-left: 0px;
    margin-right: 0px;
  }

  .price_block {
    width: 100%;
  }

  .price_block_buy {
    width: 95%;
  }

  .container {
    max-width: 800px;
  }



  .about_top img {
    display: none;
  }

  .price_figure_left {
    margin-top: 600px;
  }
}

@media (max-width: 600px) {
  .price_block_body h3 {
    font-size: 25px;
  }

  .price_block p {
    font-size: 20px;
  }

  .blog_item_top p {
    font-size: 18px !important;
  }

  .blog_item p {
    font-size: 18px !important;
  }

}

@media (max-width: 450px) {

  .response_block {
    width: 100%;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    margin-right: 20px;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    margin-left: 20px;
  }

  .header_body_content h1 {
    font-size: 25px;
  }

  .header_body_content h2 {
    font-size: 13px;
  }

  .header_body img {
    width: 100%;
  }

  .features_body img {
    width: 100%;
    margin-top: 20px;
  }

  .body_buttons_left {
    font-size: 18px;
  }

  .body_buttons_right {
    font-size: 18px;
  }

  .price_block_body h3 {
    font-size: 20px;
  }

  .price_block p {
    font-size: 16px;
  }

  .features_info_block {
    text-align: left;
  }

  .features_plot {
    font-size: 30px;
  }
}